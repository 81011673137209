.App {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.ticket {
  max-height: 100%;
  max-width:100%;
  width:100vh;
}

